exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../../../src/pages/Aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/Galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/Kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/Portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-realizacje-third-js": () => import("./../../../src/pages/RealizacjeThird.js" /* webpackChunkName: "component---src-pages-realizacje-third-js" */)
}

